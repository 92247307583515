import React from "react";
import CustomButton from "../Utility/Button";

function Footer() {
  return (
    <footer>
      {/* <div className="footer"> */}
      <div className="container">
        <p className="blue-1 text-center fw-light">
          Copyright © 2022. All rights reserved.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
